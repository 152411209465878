<template>
  <div class="home container">

    <div v-show="cardListLength == 0">
      <div ref="document" id="cardId">
        <div class="row">
          <div class="content">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="image"><img :src="'/img/avatar/'+ personalid + '.jpg'" class="card-img-top" alt="card"></div>
            <div class="avatar" v-if="image"><img :src="image" class="card-img-top" alt="card"></div>
            <div v-if="firstname" class="firstname"><span>ชื่อ <strong> {{ firstname }} </strong></span></div>
            <div v-if="lastname" class="lastname"><span>นามสกุล <strong> {{ lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ date }} </span></div>
            <div v-if="cardtype == 'N' " class="type"><span class="text-danger"> {{ cardtype }} </span></div>
            <div v-if="cardtype == 'O' " class="typeO"><span class="text-danger"> {{ cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentBack" id="cardId-Back">
        <div class="row">
          <div class="content">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>
    </div>

    <div v-show="cardListLength > 0 " v-for="(item, index) in data" :key="index">
        <div :ref="'document_' + index" :id="'doc'+index">
          <div class="row">
            <div class="content" v-for="(data, i) in item" :key="i">
              <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
              <div class="avatar" v-if="data.image"><img :src="'/img/avatar/'+ data.personalid + '.jpg'" class="card-img-top" alt="card"></div>
              <div class="avatar" v-if="data.image"><img :src="data.image" class="card-img-top" alt="card"></div>
              <div v-if="data.firstname" class="firstname"><span>ชื่อ <strong> {{ data.firstname }} </strong></span></div>
              <div v-if="data.lastname" class="lastname"><span>นามสกุล <strong> {{ data.lastname }} </strong></span></div>
              <div class="top-right"> <strong>หมายเลข {{data.runnumber}}</strong> </div>
              <div class="centered-h"><h4> {{ data.department }} </h4></div>
              <div class="datelabel"><span class="text-danger"> {{ data.date }} </span></div>
              <div v-if="data.cardtype == 'N' " class="type"><span class="text-danger"> {{ data.cardtype }} </span></div>
              <div v-if="data.cardtype == 'O' " class="typeO"><span class="text-danger"> {{ data.cardtype }} </span></div>
            </div>
          </div>
        </div>

        <div :ref="`documentBack${index}`" :id="'back'+index">
          <div class="row">
            <div class="content" v-for="i in 6" :key="i">
              <img :src="'/img/back.png'" class="card-img-top" alt="card">
            </div>
          </div>
        </div>
    </div>

    <!-- <div v-show="cardListLength > 84">
      <div ref="documentOne" id="cardIdOne">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List1" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentTwo" id="cardIdTwo">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List2" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentThree" id="cardIdThree">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List3" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentFour" id="cardIdFour">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List4" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentFive" id="cardIdFive">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List5" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentSix" id="cardIdSix">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List6" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentSeven" id="cardIdSeven">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List7" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentEight" id="cardIdEight">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List8" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentNine" id="cardIdNine">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List9" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="documentTen" id="cardIdTen">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List10" :key="item.run">
            <img :src="'/img/front'+type+'.png'" class="card-img-top" alt="card">
            <div class="avatar" v-if="item.image"><img :src="item.image" class="card-img-top" alt="card"></div>
            <div v-if="type == 1 || type ==2" class="firstname"><span>ชื่อ <strong> {{ item.firstname }} </strong></span></div>
            <div v-if="type == 1 || type ==2" class="lastname"><span>นามสกุล <strong> {{ item.lastname }} </strong></span></div>
            <div class="top-right"> <strong>หมายเลข {{item.runnumber}}</strong> </div>
            <div class="centered-h"><h4> {{ item.department }} </h4></div>
            <div class="datelabel"><span class="text-danger"> {{ item.date }} </span></div>
            <div class="type"><span class="text-danger"> {{ item.cardtype }} </span></div>
          </div>
        </div>
      </div>

      <div ref="Back1" id="Back1">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List1.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back2" id="Back2">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List2.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back3" id="Back3">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List3.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back4" id="Back4">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List4.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back5" id="Back5">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List5.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back6" id="Back6">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List6.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back7" id="Back7">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List7.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back8" id="Back8">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List8.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back9" id="Back9">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List9.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>

      <div ref="Back10" id="Back10">
        <div class="row" v-if="genType == 2">
          <div class="content" v-for="item in List10.length" :key="item">
            <img :src="'/img/back.png'" class="card-img-top" alt="card">
          </div>
        </div>
      </div>
      
    </div> -->

    <div class="modal fade bd-example-modal-lg" id="pandingModal" data-backdrop="static" data-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-sm">
            <div class="modal-content" style="width: 48px">
                <span class="fas fa-spinner fa-spin fa-3x"></span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import VueHtml2pdf from 'vue-html2pdf';
import jsPDF from 'jspdf';

export default {
  name: 'Home',
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      data: [],
      genType: '',
      department: '',
      image: '',
      firstname: '',
      lastname: '',
      date: '',
      cardtype: '',
      runnumber: '',
      type: '',
      studentnumber: '',
      cardListLength: 0,
      front: '',
      back: '',
    }
  },
  mounted() {
      const length = this.$route.params.length;
      if (length == 1) {
        this.genType = 1
        this.type = this.$route.params.info.type;
        this.department = this.$route.params.info.department;
        this.firstname = this.$route.params.info.firstname;
        this.lastname = this.$route.params.info.lastname;
        this.date = this.$route.params.info.date;
        this.cardtype = this.$route.params.info.cardtype;
        this.runnumber = this.$route.params.info.runnumber;
        this.image = this.$route.params.info.image;
        this.studentnumber = this.$route.params.info.studentnumber;
      }else{
        this.data = this.$route.params.info;
        this.genType = 2;
        this.cardListLength = this.data.length;
        this.type = this.data[0][0].type;
      }

      this.exportToPDF();
      $('#pandingModal').modal('show');
  },
  methods: {
    generateReport () {
      // this.$refs.html2Pdf.generatePdf()
      var doc = new jsPDF('p', 'pt', 'A4');
      
      const docs = document.getElementsByTagName('div')[0];

        if (docs) {
            doc.html(document.getElementById('cardId'), {
            x: 10,
            callback: function (pdf) {
              pdf.save('test.pdf');
            }
          });
        }
    },
    exportToPDF() {
      this.someModal = new bootstrap.Modal(document.getElementById('pandingModal'));

      if (this.cardListLength == 0) {
        const opt = {
          margin: 1,
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          filename: "บัตรติดหน้าอก (ด้านหน้า).pdf",
          image: { type: "png", quality: 0.98 },
          html2canvas: { scale: 4, letterRendering: true },
          jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
        }

        const optBack = {
          margin: 1,
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          filename: "บัตรติดหน้าอก (ด้านหลัง).pdf",
          
          html2canvas: { scale: 4, letterRendering: true },
          jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
        }

        //ด้านหลัง
        html2pdf().from(this.$refs.documentBack).set(optBack).save();

        html2pdf().from(this.$refs.document).set(opt).outputPdf().then(response => {
          this.someModal.hide();
          
        }).save();
      } else {

        for (let i = 0; i < this.data.length; i++) {
          const opt = {
            margin: 1,
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            filename: `บัตรติดหน้าอก (ด้านหน้า) ${i + 1}.pdf`,
            
            html2canvas: { scale: 4, letterRendering: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
          }

          const bopt = {
            margin: 1,
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            filename: `บัตรติดหน้าอก (ด้านหลัง) ${i + 1}.pdf`,
            
            html2canvas: { scale: 4, letterRendering: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
          }

          this.$nextTick(() => {
            if (i == this.data.length - 1) {
              html2pdf().from(document.getElementById(`doc${i}`)).set(opt).outputPdf().then(response => {
                this.someModal.hide();
                
              }).save();

              html2pdf().from(document.getElementById(`back${i}`)).set(bopt).save();
            }else{
              html2pdf().from(document.getElementById(`doc${i}`)).set(opt).save();

              html2pdf().from(document.getElementById(`back${i}`)).set(bopt).save();
            }
          })

        }

      }
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&family=Sarabun:wght@100;200;300;400;500&display=swap');
*{
  font-family: 'Sarabun', sans-serif !important;
}
.card-img-top{
  border: 1px solid #333;
}
.bd-example-modal-lg .modal-dialog{
    display: table;
    position: relative;
    margin: 0 auto;
    top: calc(50% - 24px);
  }
  
  .bd-example-modal-lg .modal-dialog .modal-content{
    background-color: transparent;
    border: none;
  }

  .content {
    position: relative;
    text-align: center;
    width: 430px;
    height: 260px;
  }

  .content img{
    /* border: 1px solid #333; */
  }

  /* Centered text */
  .centered-h {
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .avatar {
    position: absolute;
    top: 22%;
    left: 15%;
    transform: translate(-50%, -50%);
  }

  .avatar img {
    width: 90px;
    height: 100px;
  }

  .firstname {
    position: absolute;
    top: 42%;
    text-align: left;
    left: 4.5%;
    width: 100%;
  }

  .firstname span {
    font-size: 14px;
  }

  .lastname {
    position: absolute;
    top: 50%;
    text-align: left;
    left: 4.5%;
    width: 100%;
  }

  .lastname span {
    font-size: 14px;
  }

  .datelabel {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .datelabel span {
    font-size: 14px;
  }

  .centered {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 100%;
  }

  .type {
    position: absolute;
    bottom: 0%;
    right: 5%;
  }

  .type span{
    font-size: 60px;
    font-family: 'PT Serif', serif !important;
  }

  .typeO {
    position: absolute;
    bottom: 2%;
    right: 5%;
  }

  .typeO span{
    font-size: 40px;
    font-family: 'PT Serif', serif !important;
  }

  /* .centered span{
    font-weight: 500 !important;
  } */

  .top-right {
    position: absolute;
    top: 3%;
    right: 4%;
    text-align: right;
  }

  .no-padding{
    padding: 0 !important;
    margin: 0 !important;
  }
</style>
