import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import HomeCard from '../views/Card/Home.vue';
import Card from '../views/Card/Card.vue';
import Compare from '../views/Card/Compare.vue';
import Form from '../views/Form.vue';
import View from '../views/genPDF.vue';
import Faculty from '../views/Faculty.vue';
import RetroPage from '../views/RetroPage.vue';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import Axios from 'axios';
import HomeInfo from '../views/Info/Home.vue';
import ManageInfo from '../views/Info/ManageInfo.vue';
import ManageCardType from '../views/Info/ManageCardType.vue';
import Department from '../views/Info/Department.vue';
import External from '../views/Info/External.vue';

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: Home,
        meta: { auth: true }
    },
    {
        path: '/card/:type',
        name: 'card',
        component: Card,
        meta: { auth: ['card'] }
    },
    {
        path: '/cardpage',
        name: 'cardpage',
        component: HomeCard,
        meta: { auth: ['card'] }
    },
    {
        path: '/view',
        name: 'view',
        component: View
    },
    {
        path: '/form',
        name: 'form',
        component: Form,
        meta: { auth: ['statics'] }
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/manage',
        name: 'manage',
        component: Faculty,
        meta: { auth: ['statics'] }
    },
    {
        path: '/history',
        name: 'history',
        component: RetroPage
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
    },
    // {
    //     path: '/compare',
    //     name: 'compare',
    //     component: Compare
    // }
    // {
    //     path: '/info',
    //     name: 'info',
    //     component: HomeInfo
    // },
    {
        path: '/info-list',
        name: 'external',
        component: External
    },
    {
        path: '/manageinfo',
        name: 'manageinfo',
        component: ManageInfo,
        meta: { auth: ['card'] }
    },
    {
        path: '/managetype/:type',
        name: 'managetype',
        component: ManageCardType,
        meta: { auth: ['card'] }
    },
    {
        path: '/department',
        name: 'department',
        component: Department,
        meta: { auth: ['card'] }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const auth = to.meta.auth;
    if (!auth) return next();
    Axios.get('/api/user')
        .then(res => {
            if (res.data.status == false) {
                next({ name: 'login' });
            } else {
                if (!Array.isArray(auth)) return next();
                const userLogin = res.data.role;
                if (auth.indexOf(userLogin) >= 0) return next();
            }
        })
        .catch(err => {
            next({ name: 'login' });
        })
})

export default router